import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { APP_ID, inject, Injectable } from '@angular/core';
import { Request } from 'express';
import { from, switchMap } from 'rxjs';
import { ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig, REQUEST } from 'utils';
import { AuthService } from './auth.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  protected request = inject<Request>(REQUEST, { optional: true });
  private authService = inject(AuthService);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private appId = inject(APP_ID, { optional: true });

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (!req.url.startsWith(this.config.newMicroServiceEndpoint)) {
      return next.handle(req);
    }
    req = req.clone({
      withCredentials: true,
    });
    const ms = req.url.split(this.config.newMicroServiceEndpoint)[1].split('/')[1];
    if (['identity'].includes(ms)) {
      return next.handle(req);
    }

    const setToken = (token: string | boolean) => {
      if (token) {
        const headers: { [name: string]: string | string[] } = {};
        headers['Authorization'] = `Bearer ${token}`;
        const newReq = req.clone({ setHeaders: headers });
        return next.handle(newReq);
      }
      return next.handle(req);
    };

    if (this.appId === 'POS') {
      return from(this.authService.getTokenPos()).pipe(switchMap(token => setToken(token)));
    }

    return from(this.authService.getToken()).pipe(switchMap(token => setToken(token)));
  }
}
