import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { LanguageService } from 'language';
import { RedirectService } from 'redirect';
import { ENVIRONMENT_URLS_CONFIG_TOKEN } from 'utils';
import { AuthService } from '../auth.service';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
/**
 * If there is no language parameter it redirects to the current language versionlanguage version
 */
export class UserGuard {
  private userService = inject(UserService);
  private router = inject(Router);
  private redirectService = inject(RedirectService);
  private authService = inject(AuthService);
  private config = inject(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private languageService = inject(LanguageService);
  private isTv = false;
  private oidcJwtParam: string;
  authenticatedGuard = (allowAuthenticated = true, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    this.isTv = !!route.data?.isTv;
    this.oidcJwtParam = route.queryParams['oidcJwt'];
    return this.check(allowAuthenticated, route, state.url);
  };

  check(allowAuthenticated: boolean, route: ActivatedRouteSnapshot, url: string): Observable<boolean | UrlTree> {
    if (this.isTv && !this.oidcJwtParam) {
      return this.redirectService.redirectTo(this.config.yalloTvUrl);
    }

    return this.userService.isAuthenticated().pipe(
      switchMap(isUserAuthenticated => {
        if (allowAuthenticated) {
          if (isUserAuthenticated) {
            return of(true);
          } else {
            return of(
              this.router.createUrlTree([this.languageService.current, 'auth', 'login'], {
                queryParams: { redirectPath: url },
              })
            );
          }
        }

        if (!isUserAuthenticated) return of(true);
        if (this.isTv) return this.handleTvRedirect();
        return this.handleUserRedirect(route);
      })
    );
  }

  private handleTvRedirect(): Observable<boolean | UrlTree> {
    try {
      return this.authService
        .authComplete(this.oidcJwtParam)
        .pipe(switchMap(response => this.redirectService.redirectTo(response.data.redirectUrl)));
    } catch (error) {
      return of(true);
    }
  }

  private handleUserRedirect(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    let pathToRedirect = '/account';
    const redirectPathParam: string = route.queryParams['redirectPath'];
    if (redirectPathParam) {
      pathToRedirect = redirectPathParam;
    }

    const redirectSearchParam = route.queryParams['redirectSearch'];
    if (redirectSearchParam) {
      try {
        const redirectSearch = JSON.parse(redirectSearchParam);

        pathToRedirect +=
          '?' +
          Object.keys(redirectSearch)
            .map(key => key + '=' + redirectSearch[key])
            .join('&');
      } catch (er) {
        console.warn('Invalid redirectSearch parameter');
      }
    }

    return of(this.router.createUrlTree([pathToRedirect]));
  }
}
