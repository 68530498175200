import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { IdentityService, PosAdminService, SelfcarePassService } from '@yol-digital/ms-client';
import jwt_decode from 'jwt-decode';
import { catchError, EMPTY, map, Observable, of, switchMap, tap } from 'rxjs';
import { BrandService } from 'brand';
import { FeatureFlagService } from 'feature-flag';
import { LanguageService } from 'language';
import { ToastService } from 'toast';
import { TranslateService } from 'translate';
import {
  ENVIRONMENT_URLS_CONFIG_TOKEN,
  EnvironmentUrlsConfig,
  ObservableCachedDataLoaderService,
  StateService,
  StorageKeys,
} from 'utils';
import { AgentService } from './agent.service';
import { AuthCompleteResponse, DataFromAccessToken } from './interfaces/auth.model';
import Api = IdentityService.Api;
import SecretCodeResp = IdentityService.SecretCodeResp;
import PosSecretCodeResp = IdentityService.PosSecretCodeResp;
import MethodEnum = IdentityService.MethodEnum;
import BrandEnum = IdentityService.BrandEnum;
import LanguageEnum = IdentityService.LanguageEnum;
import SelfcareLoginReq = IdentityService.SelfcareLoginReq;
import CodeVerificationReq = IdentityService.CodeVerificationReq;
import AccessTokenResp = IdentityService.AccessTokenResp;
import PosCredentialLoginReq = IdentityService.PosCredentialLoginReq;
import PosCodeVerificationReq = IdentityService.PosCodeVerificationReq;
import UpdatePhoneNumberConfirmResp = PosAdminService.UpdatePhoneNumberConfirmResp;
import UpdatePhoneNumberConfirmReq = PosAdminService.UpdatePhoneNumberConfirmReq;
import PosAdminApi = PosAdminService.Api;
type SelfcareMethodEnum = SelfcarePassService.MethodEnum;

export interface RequestCodePayload {
  value: string;
  method: MethodEnum;
  password?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private http = inject(HttpClient);
  private brandService = inject(BrandService);
  private languageService = inject(LanguageService);
  private stateService = inject(StateService);
  private agentService = inject(AgentService);
  private translateService = inject(TranslateService);
  private toastService = inject(ToastService);
  private dataLoader = inject(ObservableCachedDataLoaderService);
  private featureFlagService = inject(FeatureFlagService);
  private platformId = inject(PLATFORM_ID);
  private api: Api;
  private posAdminApi: PosAdminApi;
  private secretCode: string;
  private refId: string;
  private oidcEndpoint: string;
  private selfcarePassApi: SelfcarePassService.Api;
  public loginMethod = signal<MethodEnum | SelfcareMethodEnum>(null);
  selfcarePassError = signal<string>(null);
  customSelfcarePassErrors = [
    'ERR_BAD_REQUEST',
    'ERR_INVALID_CODE',
    'ERR_ACCOUNT_NOT_FOUND',
    'ERR_MAX_REACHED',
    'ERR_SYSTEM_ERROR',
    'ERR_MULTIPLE_ACCOUNT_FOUND',
  ];

  constructor() {
    const http = this.http;
    const config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);

    this.api = new Api(config.newMicroServiceEndpoint, http);
    this.posAdminApi = new PosAdminApi(config.newMicroServiceEndpoint, http);
    this.oidcEndpoint = config.newMicroServiceEndpoint + '/oidc';
    this.selfcarePassApi = new SelfcarePassService.Api(config.newMicroServiceEndpoint, http);
  }

  getApiEndpoint(isTv: boolean) {
    if (isTv) {
      return this.api.tv;
    }
    return this.api.selfcare;
  }

  login(payload: RequestCodePayload, isTv = false): Observable<SecretCodeResp> {
    const { method, value, password } = payload;
    this.loginMethod.set(method);
    const apiEndpoint = this.getApiEndpoint(isTv);
    const params: SelfcareLoginReq = {
      method,
      brand: this.brandService.brand as BrandEnum,
      value: value.replace(/\s+/g, ''),
      language: this.languageService.current as LanguageEnum,
    };

    if (password) {
      params.password = password;
    }

    return apiEndpoint.login(params).pipe(
      tap((user: SecretCodeResp) => {
        if (user) {
          this.secretCode = user.secret;
        }
      })
    );
  }

  public handleSelfcarePassError(err: HttpErrorResponse) {
    const error = err.error.error;

    if (this.customSelfcarePassErrors.includes(error)) {
      this.selfcarePassError.set(error.toUpperCase());
      this.toastService.add(
        this.translateService.getTranslation(['password', 'errors', this.selfcarePassError()]),
        false
      );
      return EMPTY;
    }
  }

  validate(code: string, isTv = false): Observable<AccessTokenResp> {
    const apiEndpoint = this.getApiEndpoint(isTv);
    const payload: CodeVerificationReq = {
      secret: this.secretCode,
      code,
    };

    return apiEndpoint.validate(payload).pipe(
      tap((response: AccessTokenResp) => {
        const data: DataFromAccessToken = jwt_decode(response.accessToken);
        const accountId: string = data.sub;
        this.storeUserAuth(response.accessToken, accountId);
      })
    );
  }

  logout(): Observable<void | { error: string }> {
    return this.api.selfcare.logout().pipe(
      tap(() => {
        this.dataLoader.remove(StorageKeys.SelfCareToken);
        this.featureFlagService.setAttributeOverrides({ loggedIn: false });
      })
    );
  }

  authComplete(oidcJwt: string): Observable<AuthCompleteResponse> {
    return this.getToken().pipe(
      switchMap(accessToken => {
        if (!accessToken) return of(null);
        let headers = new HttpHeaders();
        headers = headers.set('Oidc-Jwt', oidcJwt).set('Selfcare-Jwt', accessToken);
        return this.http.post<AuthCompleteResponse>(`${this.oidcEndpoint}/auth/complete`, {}, { headers });
      })
    );
  }

  loginPos(
    username: PosCredentialLoginReq['username'],
    password: PosCredentialLoginReq['password'],
    tempChannel?: PosCredentialLoginReq['tempChannel']
  ): Observable<PosSecretCodeResp> {
    const paramsPos: PosCredentialLoginReq = {
      username,
      password,
      brand: this.brandService.brand as BrandEnum,
      ...(tempChannel ? { tempChannel } : {}),
    };

    return this.api.pos.login(paramsPos).pipe(
      tap((user: PosSecretCodeResp) => {
        if (user) {
          this.secretCode = user.secret;
        }
      })
    );
  }

  validatePos(secret: string, code?: string): Observable<DataFromAccessToken> {
    const payload: PosCodeVerificationReq = {
      secret,
      code,
    };
    return this.api.pos.validate(payload).pipe(
      map((response: AccessTokenResp) => {
        const decodedData: DataFromAccessToken = jwt_decode(response.accessToken);
        this.agentService.setAgentDetails(jwt_decode(response.accessToken));
        this.storeAgentAuth(response.accessToken);
        return decodedData; // Return only the decoded data
      })
    );
  }

  updatePhoneNumberPosStart(phoneNumber: string): Observable<PosAdminService.UpdatePhoneNumberStartResp> {
    return this.posAdminApi.public.updateStart({ phoneNumber }).pipe(
      tap((response: PosAdminService.UpdatePhoneNumberStartResp) => {
        if (response.refId) {
          this.refId = response.refId;
        }
      })
    );
  }

  updatePhoneNumberPosConfirm(code: string): Observable<
    | UpdatePhoneNumberConfirmResp
    | {
        error: string;
      }
  > {
    const payload: UpdatePhoneNumberConfirmReq = {
      refId: this.refId,
      code,
    };

    return this.posAdminApi.public.confirm(payload);
  }

  getTokenPos(): Observable<string | false | null> {
    if (!isPlatformBrowser(this.platformId)) return of(null);
    return this.dataLoader
      .get(StorageKeys.Pos, () => {
        return this.api.pos.refreshToken().pipe(
          map((resp: AccessTokenResp) => {
            this.agentService.setAgentDetails(jwt_decode(resp.accessToken));
            return resp.accessToken;
          }),
          catchError(() => of(false as const))
        );
      })
      .pipe(switchMap(token => (token ? this.validateToken(token, false) : of(false as const))));
  }

  logoutPos(): Observable<void | { error: string }> {
    return this.api.pos.logout().pipe(
      tap(() => {
        this.dataLoader.remove(StorageKeys.Pos);
      })
    );
  }

  setPassword(password: string) {
    return this.selfcarePassApi.public.setPassword({ password });
  }

  requestResetPassword(method: SelfcareMethodEnum, value: string) {
    return this.selfcarePassApi.public.requestResetPassword({
      method,
      value,
      language: this.languageService.current as LanguageEnum,
    });
  }

  resetPassword(refId: string, newPassword: string) {
    return this.selfcarePassApi.public.resetPassword({
      refId,
      newPassword,
    });
  }

  refreshToken(): Observable<string | false> {
    this.dataLoader.remove(StorageKeys.SelfCareToken);
    return this.getToken();
  }

  getToken(): Observable<string | false | null> {
    if (!isPlatformBrowser(this.platformId)) return of(null);
    return this.dataLoader
      .get(StorageKeys.SelfCareToken, () => {
        return this.api.selfcare.refreshToken().pipe(
          map(resp => {
            if ('error' in resp) throw new Error(resp.error);
            return resp.accessToken;
          }),
          catchError(() => of(false as const))
        );
      })
      .pipe(switchMap(token => (token ? this.validateToken(token) : of(false as const))));
  }

  private validateToken(token: string, autoRenew = true): Observable<string | false | null> {
    if (!token) return of(null);
    const data: DataFromAccessToken = jwt_decode(token);
    const expirationDate = data.jwtExpireUnixTime ?? data.exp;
    if (expirationDate > Date.now() / 1000) return of(token);
    if (autoRenew) return this.refreshToken();
    return of(null);
  }

  private storeUserAuth(token: string, accountId: string): void {
    this.stateService.set(StorageKeys.AccountId, accountId);
    this.dataLoader.set(StorageKeys.SelfCareToken, token);
    this.featureFlagService.setAttributeOverrides({ loggedIn: true });
  }

  private storeAgentAuth(token: string): void {
    this.dataLoader.set(StorageKeys.Pos, token);
  }
}
