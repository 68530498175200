import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AgentDetails } from 'interfaces';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  constructor() {
    // empty
  }

  private agentDetailsSubject = new BehaviorSubject<AgentDetails | null>(null);
  agentDetails$ = this.agentDetailsSubject.asObservable();

  setAgentDetails(agentDetails: AgentDetails | null): void {
    this.agentDetailsSubject.next(agentDetails);
  }
}
